<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw">
          <div class="title">我的医生</div>
          <div class="content">
            <div class="box" v-for="(v, i) in followList" :key="i">
              <div class="top">
                <img
                  v-if="!v.defaultImg && v.info.avatar != null"
                  :src="v.info.avatar"
                  @error="v.defaultImg = true"
                />
                <img v-else src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" alt />
                <div class="fr">
                  <p class="p1 one">
                    {{ v.title }}
                    <span>{{ v.info.cert_type === 1 ? "医生" : "专家" }}</span>
                  </p>
                  <p class="p2 one">{{ v.info.jigou_name || "暂无信息" }}</p>
                </div>
              </div>
              <div class="bottom">
                <p @click="cancelFollow(v)">取消收藏</p>
                <p @click="immedInquiry(v)">立即问诊</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部
import { usergetinfo } from "@/request/user";
import { getAttentionList } from "@/request/user";
import { delFav } from "@/request/user"  
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      userList: [], // 个人信息
      followList: []
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.init();
  },
  methods: {
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
      getAttentionList({
        token: this.$tokens,
        type: 1
      }).then(res => {
        if (res.code !== 1) return;
        if (res.data.data.length == 0) {
          this.$message({
            type: "Warning",
            message: "暂时没有关注的医生"
          });
          return;
        }
        res.data.data.forEach(item => (item.defaultImg = false));
        this.followList = res.data.data;
        // console.log(res);
      });
    },
    cancelFollow(v) {
      // 取消关注
      delFav({
        token: this.$token,
        id: v.id
      }).then(res => {
        if(res.code == 1) {
          this.$message({
            type: "success",
            message: res.msg
          })
        }
      })
    },
    immedInquiry(v) {
      // 立即问诊
      let id = v.info.id;
      let uid = v.info.uid;
      this.$router.push(`/doctor/doctorDetails?id=${id}&uid=${uid}`)
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 400px;
  height: 240px;
  border: 1px solid rgba(230, 230, 230, 1);
  border-top: none;
  padding-left: 56px;
  padding-top: 46px;
  img {
    width: 106px;
    height: 106px;
  }
  .fr {
    width: 200px;
    margin-right: 20px;
    padding-top: 24px;
  }
  .p1 {
    font-size: 18px;
    span {
      margin-left: 8px;
      font-size: 16px;
      color: #4eeec8;
    }
  }
  .p2 {
    margin-top: 24px;
    font-size: 16px;
    color: #4d4d4d;
  }
  .bottom {
    margin-top: 24px;
    padding-right: 56px;
    display: flex;
    justify-content: space-around;
    p {
      width: 130px;
      height: 40px;
      line-height: 40px;
      background: rgba(78, 238, 200, 1);
      color: white;
      text-align: center;
      cursor: pointer;
    }
    p:nth-of-type(2) {
      background: white;
      color: rgba(78, 238, 200, 1);
      border: 1px solid rgba(78, 238, 200, 1);
      cursor: pointer;
    }
  }
}
.content {
  min-height: 600px;
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}
.title {
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  text-indent: 36px;
  border-bottom: 1px solid #e6e6e6;
}
</style>